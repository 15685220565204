import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    FormEvent,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import { useModal } from '../hooks/useModal';
import {
    Template,
    useService as useTemplatesService,
} from '../services/Templates';
import { useOrganization } from '../services/Organization';
import { useService as useImagesService } from '../services/Images';
import {
    Tracker,
    Service as TrackersService,
    useService as useTrackersService,
} from '../services/Trackers';
import { S3_IMAGE_BUCKET } from '../config';
import {
    Context as NotificationContext,
    MessageType,
} from '../context/Notification';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import DescriptionIcon from '@mui/icons-material/Description';

import EditTemplate from './EditTemplate';
import ConfirmDeleteDialog from '../components/ConfirmDeleteDialog';
import RawData from '../components/RawData';
import HTMLEditor from '../components/HTMLEditor';
import Button from '../components/Button';
import TopNav from '../components/TopNav';
import GridPaper from '../components/GridPaper';
import EditableSectionWrapper from '../components/EditableSectionWrapper';

// Some customers create templates that link to google drive
// and then create bulk campaigns expecting them to render correctly.
// This does not work. Google Drive seems to start failing requests after
// a certain point, and this can cause renders to fail, or worse, cause them
// to render with incorrect styling.
//
// As a bit of a stopgap measure, we show this warning here. Might be
// wise to return this from the API as well (perhaps using the template formatter
// to return a `warnings` array).
const ExternalLinkWarningGridItem = ({ html }: { html: string }) => {
    try {
        // TODO(Apaar): We can probably do better than this regex
        const externalLinkMatches = useMemo(
            () => Array.from(html.matchAll(/["']https:\/\/.*?["']/g)),
            [html]
        );

        const hasBadLinks = externalLinkMatches.find(
            // TODO(Apaar): Find other bad places to link to
            (m) => m[0].includes('drive.google.com')
        );

        if (!hasBadLinks) {
            return null;
        }

        return (
            <Grid item>
                <Alert variant="outlined" color="warning">
                    Your template links to Google Drive. This works for
                    triggered mailings, but will likely render incorrectly for
                    bulk orders as requests to Google Drive will begin to fail.
                    Please use a performant file host such as AWS S3 or Dropbox.
                    You can also{' '}
                    <a href="mailto:support@postgrid.com">email us</a> to get
                    access to our file hosting API.
                </Alert>
            </Grid>
        );
    } catch (err) {
        // This is in case matchAll is not supported on the browser

        console.error(err);
        return null;
    }
};

const S3_IMG_TAG_REGEX = // Tag to find images stored in test bucket during flow for copying files to live test bucket
    new RegExp(
        `<img\\s+src="(https://${S3_IMAGE_BUCKET}(\\.s3)?\\.amazonaws\\.com/images/org_[^\\/]+/test/image_[^"]+)"`,
        'gi'
    );

export const updateHTMLForLive = (
    html: string,
    urlDictionary: Map<string, string>,
    trackerDictionary?: Map<string, string>
) => {
    const updatedHTML = html.replace(
        S3_IMG_TAG_REGEX,
        (imgDiv, testBucketUrl) => {
            const liveBucketUrl =
                urlDictionary.get(testBucketUrl) ?? testBucketUrl;
            return imgDiv.replace(testBucketUrl, liveBucketUrl);
        }
    );

    if (trackerDictionary) {
        // Match on our tracker ID followed by 22 characeters (length of our ID)
        // with an option .qrcode following for trackerse which are rendered to
        // qr codes
        const trackerRegExp = /{{(tracker_\w{22})(\.qrcode)?}}/gi;

        return updatedHTML.replace(
            trackerRegExp,
            (trackerDiv, testTrackerID) => {
                const liveTrackerID =
                    trackerDictionary.get(testTrackerID) ?? testTrackerID;

                return trackerDiv.replace(testTrackerID, liveTrackerID);
            }
        );
    }

    return updatedHTML;
};

export const copyImagesToLiveBucket = async (
    html: string,
    uploadImageFn: (originalImageURL: string) => Promise<string>
) => {
    const originalUrls = Array.from(
        // Make list of files that will need to be uploaded to live S3 bucket
        html.matchAll(S3_IMG_TAG_REGEX),
        (match) => match[1]
    );
    const uploadedUrls = await Promise.all(originalUrls.map(uploadImageFn));

    // Wait for all uploads to complete
    const urlDictionary: Map<string, string> = new Map();
    for (const [index, origURL] of originalUrls.entries()) {
        urlDictionary.set(origURL, uploadedUrls[index]);
    }

    return urlDictionary;
};

const copyTrackersToLiveMode = async (
    template: Template,
    trackersService: TrackersService
) => {
    if (!template?.metadata?.editorData?.pages) {
        return null;
    }

    const trackers: Tracker[] = (
        await Promise.all(
            template.metadata.editorData.pages.map(
                async ({
                    children,
                }: {
                    children: { trackerID?: string }[];
                }) => {
                    const trackerGetPromises: Promise<Tracker>[] = [];
                    for (const { trackerID } of children) {
                        if (trackerID) {
                            trackerGetPromises.push(
                                trackersService.get(trackerID)
                            );
                        }
                    }
                    return await Promise.all(trackerGetPromises);
                }
            )
        )
    ).flat();

    if (trackers.length === 0) {
        return null;
    }

    // Upload trackers to live mode and keep map of test to live tracker IDs
    const trackerDictionary = new Map<string, string>();
    const uploadPromises = []; // Keep track of all uploads to delete if one fails

    try {
        for (const tracker of trackers) {
            uploadPromises.push(
                trackersService
                    .create(
                        {
                            description: tracker.description,
                            redirectURLTemplate: tracker.redirectURLTemplate,
                            urlExpireAfterDays: tracker.urlExpireAfterDays,
                        },
                        {
                            forceLive: true,
                        }
                    )
                    .then((trackerCopy) => {
                        trackerDictionary.set(tracker.id, trackerCopy.id);
                    })
            );
        }

        await Promise.all(uploadPromises);

        return trackerDictionary;
    } catch (err) {
        // Let all uploads finish
        await Promise.allSettled(uploadPromises);

        // Delete uploads
        await Promise.allSettled(
            Array.from(trackerDictionary.values()).map(trackersService.delete)
        );

        throw err;
    }
};

const replaceChildSrcInPlace = (
    child: Record<string, any>,
    urlDictionary: Map<string, string>,
    trackerDictionary: Map<string, string> | null
) => {
    // Replace subchildren for groups made from merging in template editor
    if (child.children) {
        child.children = child.children.map((subChild: Record<string, any>) => {
            subChild = replaceChildSrcInPlace(
                subChild,
                urlDictionary,
                trackerDictionary
            );
            return subChild;
        });
    }

    // Base case
    if (child.type === 'image' && !child?.custom?.skipHTML) {
        child.src = urlDictionary.get(child.src) ?? child.src;
    }

    if (
        !!trackerDictionary &&
        child.type === 'tracker_image' &&
        !child.custom?.skipHTML
    ) {
        child.trackerID =
            trackerDictionary.get(child.trackerID) ?? child.trackerID;
    }

    return child;
};

const ViewTemplate = () => {
    const history = useHistory();

    const params = useParams<{ templateID: string }>();

    const service = useTemplatesService();

    const { dispatch } = useContext(NotificationContext);

    const [template, setTemplate] = useState<Template>();

    const [description, setDescription] = useState('');
    const [html, setHTML] = useState('');

    const [loading, setLoading] = useState(false);

    const { isModalOpen: deleteOpen, toggleModal: toggleDeleteModal } =
        useModal();

    const changed =
        description !== template?.description || html !== template.html;

    const org = useOrganization([history.location]);
    const imagesService = useImagesService();
    const trackersService = useTrackersService();

    const editTemp = React.useRef<any>();

    useEffect(() => {
        (async () => {
            try {
                setTemplate(undefined);

                const template = await service.tryGet(params.templateID);

                if (!template) {
                    dispatch({
                        type: MessageType.ERROR,
                        message: 'This template no longer exists.',
                    });

                    history.goBack();
                } else {
                    setTemplate(template);
                    setDescription(template.description ?? '');
                    setHTML(template.html);
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, [service, params.templateID, dispatch, history]);

    // TODO This is nearly identical to 'Copy Template to Live Mode'
    const copyTemplate =
        template &&
        (async () => {
            const prev = template;

            try {
                setTemplate(undefined);

                const copiedTemplate = await service.create({
                    description: `${template.description} (Copy)`,
                    html: template.html,
                    metadata: template.metadata,
                });

                dispatch({
                    type: MessageType.SUCCESS,
                    message: `Copied template to ${copiedTemplate.description}.`,
                });

                history.push(`/dashboard/templates/${copiedTemplate.id}`);
            } catch (err) {
                console.error(err);
            }

            setTemplate(prev);
        });

    const copyToLiveMode =
        (org?.usStripeMailingsPaymentMethod ||
            org?.stripeMailingsPaymentMethod ||
            org?.externalPaymentMethod) &&
        template &&
        !template.live &&
        (async () => {
            const prev = template;

            try {
                // Force a loader to show to prevent the user from overclicking
                setTemplate(undefined);
                setLoading(true);

                const urlDictionary = await copyImagesToLiveBucket(
                    template.html,
                    (url) =>
                        imagesService.uploadWithRetries(
                            url,
                            undefined,
                            undefined,
                            true
                        )
                );

                // Upload trackers to live mode and keep map of test to live tracker IDs
                const trackerDictionary = await copyTrackersToLiveMode(
                    template,
                    trackersService
                );

                const updatedHtml = updateHTMLForLive(
                    template.html,
                    urlDictionary,
                    trackerDictionary ?? undefined
                );

                const updatedPagesMetadata = JSON.parse(
                    JSON.stringify(template.metadata?.editorData?.pages ?? [])
                ).map((pageInfo: { children: any[] }) => {
                    return replaceChildSrcInPlace(
                        pageInfo,
                        urlDictionary,
                        trackerDictionary
                    );
                });

                // If the template is raw, it will not have metadata
                // If this is the case, skip updating the metadata
                const updatedMetadata = template.metadata?.editorData?.pages
                    ? {
                          ...template.metadata,
                          editorData: {
                              ...template.metadata?.editorData,
                              pages: updatedPagesMetadata,
                          },
                      }
                    : template.metadata;

                await service.create(
                    {
                        description: description.trim() || template.description,
                        html: updatedHtml,
                        metadata: updatedMetadata,
                    },
                    true
                );

                dispatch({
                    type: MessageType.SUCCESS,
                    message: 'Copied template to live mode.',
                });
            } catch (err) {
                console.error(err);
            }

            setLoading(false);
            setTemplate(prev);
        });

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const temp = template;
        setTemplate(undefined);

        try {
            setTemplate(
                await service.update(temp!.id, {
                    description,
                    html,
                })
            );

            dispatch({
                type: MessageType.SUCCESS,
                message: 'Updated template.',
            });
        } catch (err) {
            console.error(err);

            setTemplate(temp);
        }
    };

    const deleteTemplate = async () => {
        setTemplate(undefined);

        await service.delete(params.templateID);

        dispatch({
            type: MessageType.SUCCESS,
            message: 'Deleted template.',
        });

        history.push('/dashboard/templates');
    };

    return (
        <>
            <TopNav />
            <GridPaper container={false}>
                <form onSubmit={onSubmit}>
                    <ConfirmDeleteDialog
                        open={deleteOpen}
                        onClose={toggleDeleteModal}
                        title="Delete Template"
                        text="Are you sure you want to delete this template?"
                        confirm={deleteTemplate}
                    />
                    <Grid container direction="column" spacing={2}>
                        <Grid
                            container
                            item
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography variant="h5">
                                    Template Details
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={toggleDeleteModal}
                                            disabled={!template}
                                        >
                                            Delete Template
                                        </Button>
                                    </Grid>

                                    {template &&
                                        template.metadata?.editorData && (
                                            <Grid item>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={() =>
                                                        history.push(
                                                            `/dashboard/templates/${template.id}/edit`
                                                        )
                                                    }
                                                >
                                                    Open Fullscreen Editor
                                                </Button>
                                            </Grid>
                                        )}

                                    {template && (
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={() => {
                                                    copy(template.id);
                                                    dispatch({
                                                        message:
                                                            'Copied template ID to clipboard.',
                                                        type: MessageType.SUCCESS,
                                                    });
                                                }}
                                            >
                                                Copy Template ID
                                            </Button>
                                        </Grid>
                                    )}

                                    {copyTemplate && (
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={copyTemplate}
                                            >
                                                Copy Template
                                            </Button>
                                        </Grid>
                                    )}

                                    {copyToLiveMode && (
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={copyToLiveMode}
                                            >
                                                Copy to Live Mode
                                            </Button>
                                        </Grid>
                                    )}

                                    <Grid item>
                                        {template?.metadata?.editorData ? (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                onClick={async () => {
                                                    setLoading(true);
                                                    try {
                                                        await editTemp.current?.saveTemplate();
                                                    } finally {
                                                        setLoading(false);
                                                    }
                                                }}
                                            >
                                                Save Template
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={!template || !changed}
                                            >
                                                Save Template
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <ExternalLinkWarningGridItem html={html} />

                        <Grid item>
                            <Alert variant="outlined" color="info">
                                Modifying a template will not affect orders that
                                have already been generated with an older
                                version.
                            </Alert>
                        </Grid>

                        {template ? (
                            <Grid container item direction="column" spacing={2}>
                                <EditableSectionWrapper
                                    item
                                    title="Description"
                                    icon={DescriptionIcon}
                                >
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                    />
                                </EditableSectionWrapper>
                                {template.metadata?.editorData ? (
                                    <EditableSectionWrapper
                                        item
                                        title={'Edit Template'}
                                        icon={DescriptionIcon}
                                    >
                                        <EditTemplate
                                            fullScreen={false}
                                            updatedDescription={description}
                                            ref={editTemp}
                                        />
                                    </EditableSectionWrapper>
                                ) : (
                                    <EditableSectionWrapper
                                        item
                                        title="HTML"
                                        icon={DescriptionIcon}
                                    >
                                        <HTMLEditor
                                            fullWidth
                                            variant="outlined"
                                            value={html}
                                            onChange={(e) => {
                                                setHTML(e.target.value);
                                            }}
                                        />
                                    </EditableSectionWrapper>
                                )}
                                <Grid item>
                                    <RawData obj={template} />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid
                                container
                                item
                                style={{ minHeight: '20vh' }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </GridPaper>
        </>
    );
};

export default ViewTemplate;
