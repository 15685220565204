import React, { PropsWithoutRef } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import CloseIcon from '@mui/icons-material/Close';

import { UploadIcon } from './Icons';

const FileUpload = (
    props: PropsWithoutRef<{
        label: string;
        accept: string;
        file: File | null;
        setFile: (file: File | null) => void;
        onFileChange?:
            | ((file: File | null) => void)
            | ((file: File | null) => Promise<void>);
        required?: boolean;
        disabled?: boolean;
    }>
) => {
    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newFile = e.target.files?.[0] || null;
        props.setFile(newFile);

        if (props.onFileChange) {
            props.onFileChange(newFile);
        }
    };

    return (
        <Paper
            variant="outlined"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '54px',
                color: '#D1D1D1',
                borderColor: 'transparent',
            }}
            data-testid="file-upload-component"
        >
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
            >
                {!props.file ? (
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            component="label"
                            disabled={props.disabled}
                            size="large"
                            tabIndex={-1}
                            style={{
                                textTransform: 'initial',
                            }}
                            data-testid="file-upload-button"
                        >
                            <span
                                style={{
                                    marginRight: '10px',
                                    marginBottom: '-5px',
                                }}
                            >
                                <UploadIcon
                                    color={
                                        props.disabled ? '#d4d4d4' : '#2f71eb'
                                    }
                                />
                            </span>
                            {props.label}
                            <input
                                type="file"
                                accept={props.accept}
                                required={props.required}
                                style={{
                                    opacity: 0,
                                    width: 1,
                                }}
                                onChange={handleFileUpload}
                                data-testid="file-input"
                            />
                        </Button>
                    </Grid>
                ) : (
                    <>
                        <Grid item>
                            <Typography variant="body2">
                                {props.file.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                size="large"
                                onClick={() => props.setFile(null)}
                                data-testid="file-abort-button"
                            >
                                <CloseIcon fontSize="small" />
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Paper>
    );
};

export default FileUpload;
