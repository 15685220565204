import React from 'react';

import Box from '@mui/material/Box';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

interface RowErrorsProps {
    validRowCount: number;
    invalidRowCount: number;
    errors: string[];
}

const RowErrors = ({
    validRowCount,
    invalidRowCount,
    errors,
}: RowErrorsProps) => {
    if (errors.length) {
        return (
            <DialogContentText>
                {errors.map((err) => {
                    return (
                        <Typography
                            color="secondary"
                            component="span"
                            key={err}
                        >
                            {err}
                        </Typography>
                    );
                })}
            </DialogContentText>
        );
    }

    if (validRowCount > 0) {
        return (
            <DialogContentText
                data-testid="upload-contacts-row-info"
                component="div"
            >
                <Box display="flex" flexDirection="column">
                    <Typography component="span">
                        Found {validRowCount} contact
                        {validRowCount === 1 ? '' : 's'} in uploaded file.
                    </Typography>
                    {invalidRowCount > 0 && (
                        <Typography color="secondary" component="span">
                            There{' '}
                            {invalidRowCount === 1
                                ? `is ${invalidRowCount} row`
                                : `are ${invalidRowCount} rows`}{' '}
                            missing an address, country code, a name/company
                            name. {invalidRowCount === 1 ? 'This' : 'These'}{' '}
                            will be skipped.
                        </Typography>
                    )}
                </Box>
            </DialogContentText>
        );
    }

    if (validRowCount === 0 && invalidRowCount > 0) {
        return (
            <DialogContentText data-testid="upload-contacts-no-valid-contacts">
                <Typography color="secondary" component="span">
                    Your file contains no valid contacts. Please ensure that
                    each contact has an address, country code, and a name or
                    company name.
                </Typography>
            </DialogContentText>
        );
    }

    return (
        <DialogContentText data-testid="upload-contacts-empty-file">
            <Typography color="secondary" component="span">
                The file you have uploaded is empty.
            </Typography>
        </DialogContentText>
    );
};

export default RowErrors;
