import React, { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { SSORoutes } from '../routes';
import { Context, ActionType } from '../context/Auth';
import { Context as NotificationContext } from '../context/Notification';

import SignupWrapper, { SignupWrapperProps } from './SignupWrapper';
import { useHistory } from 'react-router-dom';

type SignupWithSSOProps = Partial<SignupWrapperProps> & {
    /** Populate email field to the provided value */
    email?: string;
    /** A route to redirect to after signing up with Auth0 */
    redirectTo?: SSORoutes;
};

const SignupWithSSO = ({ redirectTo, email, ...props }: SignupWithSSOProps) => {
    const { isLoading, isAuthenticated, loginWithPopup, logout } = useAuth0();
    const { dispatch } = useContext(Context);
    const { dispatchError } = useContext(NotificationContext);
    const history = useHistory();

    const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        dispatch({
            type: ActionType.LOGOUT,
        });

        try {
            await loginWithPopup({
                prompt: 'login',
                // NOTE(Apaar): This is never applicable for us because it's only used for
                // username/password auth via Auth0 and we never want to support that. In fact,
                // this causes issues for signup using social stuff like google.
                //
                // screen_hint: 'signup',
                login_hint: email,
            });

            if (redirectTo) {
                history.replace(redirectTo);
            }
        } catch (err) {
            logout({ localOnly: true });
            dispatchError(
                'Something went wrong while signing up.  Please try again.'
            );
        }
    };

    return (
        <SignupWrapper
            {...props}
            onSubmit={handleSignup}
            loginLink={SSORoutes.LOGIN}
            submitDisabled={isLoading || isAuthenticated}
            buttonText="Sign Up With SSO"
        />
    );
};

export default SignupWithSSO;
